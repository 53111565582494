import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HourglassComponent from "./components/UI/HourglassComponent/HourglassComponent";
import {
  ROUTE_BOOKING_CONFIRMATION,
  ROUTE_EVENT_BOOKING_FEEDBACK_PAGE_V2,
  ROUTE_EVENT_BOOKING_V2,
  ROUTE_FERTILITY_CLINIC,
  CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUCCESS,
  CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUMMARY_PAGE,
  CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENTS,
  ROUTE_PREGNANCY_SLOT_BOOKING,
  ROUTE_REGISTRATION_SUCCESSFUL_PAGE,
  ROUTE_THANK_YOU_FORM,
  ROUTE_TS_GTM_REGISTRATION,
} from "./utils/constants/global-constants";
import FertilityClinicPage from "./pages/FertilityClinicPage";
import FertilityClinicAppointmentsPage from "./pages/FertilityClinicAppointmentsPage";
import PregnancySlotBookingPage from "./pages/PregnancySlotBookingPage";
import BookingConfirmationPage from "./pages/BookingConfirmationPage";
import FertilityClinicAppointmentSuccessPage from "./pages/FertilityClinicAppointmentSuccessPage";
import FertilityClinicAppointmentsSummaryPage from "./pages/FertilityClinicAppointmentsSummaryPage";
import { ROUTE_ID_DIRECTORY } from "./utils/constants/fertility-clinic-constants";
const ThankyouFormPage = lazy(() => import("./pages/ThankyouFormPage"));
const RegistrationSuccessPage = lazy(() =>
  import("./pages/registrationSuccessPage")
);
const DummyPage = lazy(() => import("./pages/dummyPage"));
const WcpRenewalPage = lazy(() => import("./pages/wcpRenewalPage"));
const SppRenewalPage = lazy(() => import("./pages/SppRenewalPage"));
const PcpRenewalPage = lazy(() => import("./pages/PcpRenewalPage"));
const ReddyParentingUserForm = lazy(() =>
  import("./pages/ReddyParentingUserForm")
);
const UserForm = lazy(() => import("./pages/WcpUserForm"));
const WcpPlans = lazy(() => import("./pages/WcpPlans"));
const StandardPlans = lazy(() => import("./pages/StandardPlans"));
const RootLayout = lazy(() => import("./pages/Root"));
const PaymentSuccessFeedBackPage = lazy(() =>
  import("./pages/PaymentSuccessFeedBackPage")
);
const SlotBookingPage = lazy(() => import("./pages/SlotBooking"));
const SlotBookingFeedBackPage = lazy(() =>
  import("./pages/SlotBookingFeedBackPage")
);
const Workshop = lazy(() => import("./pages/Workshop"));
const SpUserForm = lazy(() => import("./pages/SpUserForm"));
const SuperParentingForm = lazy(() => import("./pages/SuperParentingForm"));
const PrescriptionPage = lazy(() => import("./pages/PrescriptionPage"));
const CalendlyReportPage = lazy(() => import("./pages/CalendlyReportPage"));
const EventBookingPage = lazy(() => import("./pages/EventBookingPage"));
const EventBookingPageV2 = lazy(() => import("./pages/EventBookingPageV2"));
const EventBookingFeedbackPage = lazy(() =>
  import("./pages/EventBookingFeedbackPage")
);
const EventBookingFeedbackPageV2 = lazy(() =>
  import("./pages/EventBookingFeedbackPageV2")
);
const FertilityBookingPage = lazy(() => import("./pages/FertilityBookingPage"));
const ReddyPregnancyUserForm = lazy(() =>
  import("./pages/ReddyPregnancyUserForm")
);

function App() {
  const LoadingElement = <HourglassComponent />;
  //  (
  //   <p style={{ textAlign: "center", paddingTop: "50vh" }}>Loading...</p>
  // );

  const router = createBrowserRouter([
    {
      path: "/",
      children: [
        {
          index: true,
          id : ROUTE_ID_DIRECTORY.fertilityClinic,
          element: (
            <Suspense fallback={LoadingElement}>
              <FertilityClinicPage />
            </Suspense>
          ),
        },
        {
          path: CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENTS,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={LoadingElement}>
                  <FertilityClinicAppointmentsPage />
                </Suspense>
              ),
            },
            {
              path: CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUMMARY_PAGE,
              children: [
                {
                  index: true,
                  element: (
                    <Suspense fallback={LoadingElement}>
                      <FertilityClinicAppointmentsSummaryPage />
                    </Suspense>
                  ),
                },
                {
                  path: CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUCCESS,
                  id : ROUTE_ID_DIRECTORY.successPage,
                  element: (
                    <Suspense fallback={LoadingElement}>
                      <FertilityClinicAppointmentSuccessPage />
                    </Suspense>
                  ),
                  loader: (meta) =>
                    import("./pages/FertilityClinicAppointmentSuccessPage").then((module) =>
                      module.loader(meta)
                    ),
                },
              ],
            },
          ],
        },
      ],
    },,
  ]);

  return <RouterProvider router={router} />;
}

export default App;
